export const isoFormatDate = (date) => {
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};

export const truncateString = (str, maxLength) => {
  if (str.length < maxLength) {
    return str;
  }
  return str.substring(0, maxLength - 3) + "...";
};

export const updateMissingFields = (
  fieldName,
  fieldValue,
  options,
  missingFields,
  setMissingFields
) => {
  if (fieldValue) {
    if (!options.includes(fieldValue)) {
      if (!missingFields[fieldName]) {
        setMissingFields({
          ...missingFields,
          [fieldName]: true,
        });
      }
    } else {
      if (missingFields[fieldName]) {
        setMissingFields({ ...missingFields, [fieldName]: false });
      }
    }
  } else {
    if (missingFields[fieldName]) {
      setMissingFields({ ...missingFields, [fieldName]: false });
    }
  }
};

export const capitalize = (str) => {
  return str
    .split(" ")
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(" ");
};

export const areArraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  arr1 = arr1.slice().sort();
  arr2 = arr2.slice().sort();

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};
