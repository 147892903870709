import { useContext, useEffect, useState } from "react";
import {
  Checkbox,
  Dropdown,
  Flex,
  Modal,
  ModalContent,
  ModalFooterButtons,
  ModalHeader,
  Text,
} from "monday-ui-react-core";
import { Heading } from "monday-ui-react-core/next";
import ConnectionContext from "../../context/ConnectionContext";

const RecurringEventAdvanceDaysRecipe = ({ type, recipeText }) => {
  const { localIntegrationConfig, setLocalIntegrationConfig, isUserProviderAccountOwner } =
    useContext(ConnectionContext);

  const [enabled, setEnabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [days, setDays] = useState(0);
  const options = [30, 60, 90].map((days) => ({ value: days, label: days }));

  useEffect(() => {
    if (type in localIntegrationConfig) {
      setEnabled(true);
      setDays(localIntegrationConfig[type].days);
    }
  }, [type, localIntegrationConfig]);

  const onClick = (e) => {
    e.preventDefault();
    if (!isUserProviderAccountOwner) {
      return;
    }

    if (!enabled) {
      setShowModal(true);
    } else {
      setDays(0);
      setEnabled(false);

      const config = { ...localIntegrationConfig };
      delete config[type];
      setLocalIntegrationConfig(config);
    }
  };

  const selectedOption = days ? options.filter((option) => option.value === days)[0] : null;

  const onChange = (option) => {
    setDays(option ? option.value : 0);
  };

  const onConfirm = () => {
    if (!days) {
      return;
    }
    setShowModal(false);
    setEnabled(true);
    setLocalIntegrationConfig({ ...localIntegrationConfig, [type]: { days } });
  };

  const finalRecipeText = () => {
    let text = recipeText;
    if (selectedOption) {
      return text.replace("{number}", `[${selectedOption.label}]`);
    } else {
      return text.replace("{number}", "__________");
    }
  };

  return (
    <div className={`recipe ${enabled ? "recipe-enabled" : "recipe-disabled"}`}>
      <Flex direction="Column" gap={10} onClick={onClick}>
        <Flex gap={10}>
          <Checkbox checked={enabled} disabled={!isUserProviderAccountOwner} />

          <Text type="text1" ellipsis={false} className="recipe-text">
            {finalRecipeText()}
          </Text>
        </Flex>
      </Flex>

      <Modal
        contentSpacing
        id="story-book-modal"
        onClose={() => setShowModal(false)}
        title="Edit Integration"
        show={showModal}
        width={500}
      >
        <ModalHeader>
          <Heading type="h2">Edit Integration</Heading>
        </ModalHeader>
        <ModalContent>
          <Flex
            direction="Column"
            gap={10}
            align="Start"
            style={{ marginTop: "1rem", minHeight: "30vh" }}
          >
            <Flex gap={10}>
              <Text type="text1" ellipsis={false}>
                Create items for recurring calendar events upto
              </Text>
            </Flex>
            <Flex gap={10}>
              <div className="recipe-selector">
                <Dropdown
                  size="small"
                  clearable={false}
                  options={options}
                  value={selectedOption}
                  onChange={onChange}
                />
              </div>
              <Text type="text1" ellipsis={false}>
                days in advance
              </Text>
            </Flex>
          </Flex>
        </ModalContent>
        <ModalFooterButtons
          onPrimaryButtonClick={onConfirm}
          onSecondaryButtonClick={() => setShowModal(false)}
          primaryButtonText="Confirm"
          secondaryButtonText="Cancel"
        />
      </Modal>
    </div>
  );
};

export default RecurringEventAdvanceDaysRecipe;
