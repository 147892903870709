import { useContext, useEffect, useState } from "react";
import {
  Checkbox,
  Dropdown,
  Flex,
  Modal,
  ModalContent,
  ModalFooterButtons,
  ModalHeader,
  Text,
} from "monday-ui-react-core";
import { Heading } from "monday-ui-react-core/next";
import ConnectionContext from "../../context/ConnectionContext";

const StatusRecipe = ({ type, recipeText }) => {
  const { columns, localIntegrationConfig, setLocalIntegrationConfig, isUserProviderAccountOwner } =
    useContext(ConnectionContext);

  const [enabled, setEnabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [statusColumnId, setStatusColumnId] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [valueOptions, setValueOptions] = useState([]);

  useEffect(() => {
    if (type in localIntegrationConfig) {
      setEnabled(true);

      const { statusColumnId, statusValue } = localIntegrationConfig[type];
      setStatusColumnId(statusColumnId);
      setStatusValue(statusValue);
    }
  }, [type, localIntegrationConfig]);

  const onClick = (e) => {
    e.preventDefault();
    if (!isUserProviderAccountOwner) {
      return;
    }

    if (!enabled) {
      setShowModal(true);
    } else {
      setStatusColumnId("");
      setStatusValue("");
      setEnabled(false);

      const config = { ...localIntegrationConfig };
      delete config[type];
      setLocalIntegrationConfig(config);
    }
  };

  const columnOptions = columns
    .filter((column) => column.type === "status")
    .map(({ id, title, settings_str }) => ({
      value: id,
      label: title,
      possibleValues: JSON.parse(settings_str).labels,
    }));

  useEffect(() => {
    if (statusColumnId) {
      const { possibleValues } = columnOptions.filter(
        (option) => option.value === statusColumnId
      )[0];

      setValueOptions(
        Object.entries(possibleValues).map(([key, value]) => ({
          value: key,
          label: value,
        }))
      );
    }
  }, [statusColumnId, columnOptions]);

  const selectedColumnOption = statusColumnId
    ? columnOptions.filter((option) => option.value === statusColumnId)[0]
    : null;

  const selectedValueOption = statusValue
    ? valueOptions.filter((option) => option.value === statusValue)[0]
    : null;

  const onColumnChange = (option) => {
    setStatusColumnId(option ? option.value : "");
    setStatusValue("");

    setValueOptions(
      option
        ? Object.entries(option.possibleValues).map(([key, value]) => ({
            value: key,
            label: value,
          }))
        : []
    );
  };

  const onValueChange = (option) => {
    setStatusValue(option ? option.value : "");
  };

  const onConfirm = () => {
    if (!statusColumnId || !statusValue) {
      return;
    }
    setShowModal(false);
    setEnabled(true);

    setLocalIntegrationConfig({
      ...localIntegrationConfig,
      [type]: { statusColumnId, statusValue },
    });
  };

  const finalRecipeText = () => {
    let text = recipeText;
    if (selectedColumnOption && selectedValueOption) {
      return text
        .replace("{statusColumnId}", `[${selectedColumnOption.label}]`)
        .replace("{statusValue}", `[${selectedValueOption.label}]`);
    } else {
      return text.replace("{statusColumnId}", "__________").replace("{statusValue}", "__________");
    }
  };

  return (
    <div className={`recipe ${enabled ? "recipe-enabled" : "recipe-disabled"}`}>
      <Flex direction="Column" gap={10} onClick={onClick}>
        <Flex gap={10}>
          <Checkbox checked={enabled} disabled={!isUserProviderAccountOwner} />

          <Text type="text1" ellipsis={false} className="recipe-text">
            {finalRecipeText()}
          </Text>
        </Flex>
      </Flex>

      <Modal
        contentSpacing
        id="story-book-modal"
        onClose={() => setShowModal(false)}
        title="Edit Integration"
        show={showModal}
        width={500}
      >
        <ModalHeader>
          <Heading type="h2">Edit Integration</Heading>
        </ModalHeader>
        <ModalContent>
          <Flex
            direction="Column"
            gap={10}
            align="Start"
            style={{ marginTop: "1rem", minHeight: "30vh" }}
          >
            <Flex gap={10}>
              <Text type="text1" ellipsis={false}>
                When status column
              </Text>
              <div className="recipe-selector">
                <Dropdown
                  size="small"
                  clearable={false}
                  options={columnOptions}
                  value={selectedColumnOption}
                  onChange={onColumnChange}
                />
              </div>
              <Text type="text1" ellipsis={false}>
                changes to
              </Text>
            </Flex>

            <Flex gap={10}>
              <Text type="text1" ellipsis={false}>
                value
              </Text>
              <div className="recipe-selector">
                <Dropdown
                  size="small"
                  clearable={false}
                  options={valueOptions}
                  value={selectedValueOption}
                  onChange={onValueChange}
                />
              </div>
              <Text type="text1" ellipsis={false}>
                , {type === "5" ? "create" : "delete"} event in calendar
              </Text>
            </Flex>
          </Flex>
        </ModalContent>
        <ModalFooterButtons
          onPrimaryButtonClick={onConfirm}
          onSecondaryButtonClick={() => setShowModal(false)}
          primaryButtonText="Confirm"
          secondaryButtonText="Cancel"
        />
      </Modal>
    </div>
  );
};

export default StatusRecipe;
