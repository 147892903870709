import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Button, Loader } from "monday-ui-react-core";
import ConnectionContext from "../../context/ConnectionContext";

const ButtonGroup = () => {
  const {
    isSubitemMode,
    isActive,
    isLoading,
    inEditMode,
    setInEditMode,
    isUserProviderAccountOwner,
    isUserBoardOwner,
    isUserViewer,
    createConnection,
    updateConnection,
    deleteConnection,
    importExistingEvents,
  } = useContext(ConnectionContext);

  const navigate = useNavigate();
  const isViewerOrNotBoardOwner = isUserViewer || !isUserBoardOwner;

  return (
    <Flex className="button-group" justify="Center">
      {isLoading ? (
        <Loader size={40} />
      ) : !isActive ? (
        <Button
          color="positive"
          className="button"
          onClick={createConnection}
          disabled={isViewerOrNotBoardOwner}
        >
          Create Connection
        </Button>
      ) : inEditMode ? (
        <Button
          color="positive"
          className="button"
          onClick={updateConnection}
          disabled={!isUserProviderAccountOwner || isViewerOrNotBoardOwner}
        >
          Save Changes
        </Button>
      ) : (
        <Flex direction="Column" gap={10}>
          <Flex gap={10}>
            <Button
              color="primary"
              className="button-2"
              onClick={() => setInEditMode(true)}
              disabled={!isUserProviderAccountOwner || isViewerOrNotBoardOwner}
            >
              Edit
            </Button>
            <Button
              color="negative"
              className="button-2"
              onClick={deleteConnection}
              disabled={isViewerOrNotBoardOwner}
            >
              Delete
            </Button>
          </Flex>

          <Button
            color="positive"
            onClick={() => navigate("/integration")}
            disabled={isViewerOrNotBoardOwner}
            style={{ width: "100%" }}
          >
            Manage Integration
          </Button>

          {!isSubitemMode && (
            <Button
              color="primary"
              onClick={importExistingEvents}
              disabled={isViewerOrNotBoardOwner}
              style={{ width: "100%" }}
            >
              Import Existing Events
            </Button>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default ButtonGroup;
