import { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import mondaySdk from "monday-sdk-js";
import { ThemeProvider } from "monday-ui-react-core";
import { ConnectionProvider } from "./context/ConnectionContext";
import ConnectionSetup from "./pages/ConnectionSetup";
import IntegrationSetup from "./pages/IntegrationSetup";

const monday = mondaySdk();

const App = () => {
  const [context, setContext] = useState({});

  useEffect(() => {
    monday.listen("context", (res) => {
      setContext((previousContext) => (previousContext === res.data ? previousContext : res.data));
    });
  }, []);

  return (
    <div className={`${context.theme}-app-theme`}>
      <ThemeProvider theme={context.themeConfig} className="App">
        <ConnectionProvider>
          <Router>
            <Routes>
              <Route path="/" element={<ConnectionSetup />} />
              <Route path="/integration" element={<IntegrationSetup />} />
            </Routes>
          </Router>
        </ConnectionProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
