import { useContext } from "react";
import { AttentionBox, Flex } from "monday-ui-react-core";
import ConnectionContext from "../../context/ConnectionContext";

const ValidationError = () => {
  const { validationError } = useContext(ConnectionContext);

  return (
    validationError && (
      <Flex>
        <AttentionBox className="validation-error" text={validationError} type="danger" />
      </Flex>
    )
  );
};

export default ValidationError;
