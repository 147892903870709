import { useContext } from "react";
import { AttentionBox, Flex, Link, Loader, Text, Tooltip } from "monday-ui-react-core";
import ConnectIcon from "monday-ui-react-core/dist/icons/Connect";
import InfoIcon from "monday-ui-react-core/dist/icons/Info";
import { Heading } from "monday-ui-react-core/next";
import "monday-ui-react-core/tokens";
import AppHeading from "../components/common/AppHeading";
import MessageToast from "../components/common/MessageToast";
import SubscribeNudge from "../components/common/SubscribeNudge";
import ButtonGroup from "../components/connection/ButtonGroup";
import CalendarSelector from "../components/connection/CalendarSelector";
import CustomDescriptionBuilder from "../components/connection/CustomDescriptionBuilder";
import EmailIdSelector from "../components/connection/EmailIdSelector";
import FieldOptionSelector from "../components/connection/FieldOptionSelector";
import InviteesColumnsSelector from "../components/connection/InviteesColumnsSelector";
import ModeSelector from "../components/connection/ModeSelector";
import OAuthScreen from "../components/connection/OAuthScreen";
import SettingValueSelector from "../components/connection/SettingValueSelector";
import ValidationError from "../components/connection/ValidationError";
import ConnectionContext from "../context/ConnectionContext";
import {
  TITLE_FIELD,
  START_TIME_FIELD,
  END_TIME_FIELD,
  DESCRIPTION_FIELD,
  LOCATION_FIELD,
  INVITEES_FIELD,
  EVENT_STATUS_FIELD,
  EVENT_LINK_FIELD,
  SYNC_PERIOD,
  TIMELINE_FIELD,
  DURATION_FIELD,
} from "../utils/constants";

const ConnectionSetup = () => {
  const {
    appName,
    isOAuthComplete,
    approvedScopes,
    showModeSelector,
    isConnectionLoading,
    timelineFieldEnabled,
    durationFieldEnabled,
    isActive,
    isUserProviderAccountOwner,
    isUserBoardOwner,
    isUserViewer,
  } = useContext(ConnectionContext);

  const appPageUrl = appName.toLowerCase().replaceAll(" ", "-");

  return (
    <>
      {isConnectionLoading ? (
        <Loader color="var(--secondary-text-color)" size={64} />
      ) : (
        <>
          {isUserViewer && (
            <AttentionBox
              title="Important"
              text='As a viewer, you are unable to use the app "Google Calendar Sync"'
              className="monday-storybook-attention-box_box"
              type="warning"
            />
          )}

          <Text align="center" type="text1" style={{ marginTop: "1rem" }}>
            Need help with something? Checkout our{" "}
            <Link
              href={`https://superworkday.com/${appPageUrl}/how-to-use.html`}
              text="how to use guide"
              inheritFontSize
              inlineText
            />
          </Text>

          {!isOAuthComplete ? (
            <OAuthScreen />
          ) : (
            <>
              {!isUserViewer &&
                (!isUserBoardOwner ? (
                  <AttentionBox
                    title="Important"
                    text="Only board owners can create/delete the connection for this board"
                    className="monday-storybook-attention-box_box"
                    type="warning"
                  />
                ) : (
                  !isUserProviderAccountOwner &&
                  isActive && (
                    <AttentionBox
                      title="Important"
                      text="Only Google account owner can update the connection/integration"
                      className="monday-storybook-attention-box_box"
                      type="warning"
                    />
                  )
                ))}

              <AppHeading />

              <EmailIdSelector />
              <CalendarSelector />
              {showModeSelector && <ModeSelector />}

              <Flex gap={10} justify="Center" className="sub-heading">
                <Heading weight="light" type="h3">
                  Connect Fields to Columns
                </Heading>
                <ConnectIcon size={10} />
              </Flex>

              <Flex direction="Column" gap={20}>
                <FieldOptionSelector fieldId={TITLE_FIELD} />
                {!timelineFieldEnabled ? (
                  <>
                    <FieldOptionSelector fieldId={START_TIME_FIELD} />
                    {!durationFieldEnabled ? (
                      <FieldOptionSelector fieldId={END_TIME_FIELD} />
                    ) : (
                      <FieldOptionSelector fieldId={DURATION_FIELD} />
                    )}
                  </>
                ) : (
                  <FieldOptionSelector fieldId={TIMELINE_FIELD} />
                )}
                <FieldOptionSelector fieldId={DESCRIPTION_FIELD} />
                <FieldOptionSelector fieldId={LOCATION_FIELD} />
              </Flex>

              <Flex gap={10} justify="Center" className="sub-heading">
                <Heading weight="light" type="h3">
                  {"1-way synced fields : Board -> Calendar"}
                </Heading>
                <Tooltip
                  content="These fields flow 1-way from monday to Google Calendar. Please ensure that relevant integration rules are enabled for these to work properly"
                  withMaxWidth
                  position="left"
                >
                  <InfoIcon size={15} />
                </Tooltip>
              </Flex>

              <Flex direction="Column" gap={20}>
                <InviteesColumnsSelector fieldId={INVITEES_FIELD} />

                {approvedScopes.includes("users:read") && approvedScopes.includes("teams:read") && (
                  <CustomDescriptionBuilder />
                )}
              </Flex>

              <Flex gap={10} justify="Center" className="sub-heading">
                <Heading weight="light" type="h3">
                  {"1-way synced fields : Calendar -> Board"}
                </Heading>
                <Tooltip
                  content="These fields flow 1-way from Google Calendar to monday. Please ensure that relevant integration rules are enabled for these to work properly"
                  withMaxWidth
                  position="left"
                >
                  <InfoIcon size={15} />
                </Tooltip>
              </Flex>

              <Flex direction="Column" gap={20}>
                <FieldOptionSelector fieldId={EVENT_LINK_FIELD} />
                <FieldOptionSelector fieldId={EVENT_STATUS_FIELD} />
              </Flex>

              <Heading weight="light" type="h3">
                Other Settings
              </Heading>

              <Flex direction="Column" gap={20}>
                <SettingValueSelector type={SYNC_PERIOD} />
              </Flex>

              <ValidationError />
              <SubscribeNudge />

              <ButtonGroup />
            </>
          )}
        </>
      )}

      <MessageToast />
    </>
  );
};

export default ConnectionSetup;
