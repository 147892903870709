import axios from "axios";
import { decodeJwt } from "jose";
import { createContext, useEffect, useState } from "react";
import mondaySdk from "monday-sdk-js";
import {
  TITLE_FIELD,
  START_TIME_FIELD,
  END_TIME_FIELD,
  DESCRIPTION_FIELD,
  LOCATION_FIELD,
  EVENT_STATUS_FIELD,
  EVENT_LINK_FIELD,
  BASE_API_URL,
  CONNECTION_API_URL,
  GOOGLE_CALENDAR_SYNC,
  OUTLOOK_CALENDAR_SYNC,
  AUTH_API_URL,
  CUSTOM_DESCRIPTION_FIELD,
  CUSTOM_DESCRIPTION_SPECIAL_VALUE,
  INVITEES_FIELD,
  SYNC_PERIOD,
  TIMELINE_FIELD,
  DURATION_FIELD,
} from "../utils/constants";
import { areArraysEqual, truncateString } from "../utils/utils";

export const fieldInfos = {
  [TITLE_FIELD]: {
    name: "Event Title",
    columnTypes: ["name", "text"],
    clearable: false,
  },
  [START_TIME_FIELD]: {
    name: "Start Time",
    columnTypes: ["date"],
    clearable: false,
  },
  [END_TIME_FIELD]: {
    name: "End Time",
    columnTypes: ["date"],
    clearable: false,
  },
  [TIMELINE_FIELD]: {
    name: "Timeline [Start - End]",
    columnTypes: ["timeline"],
    clearable: false,
  },
  [DURATION_FIELD]: {
    name: "Event Duration",
    simpleField: true,
    values: [15, 30, 60, 90, 120],
    suffix: "minutes",
    clearable: false,
  },
  [DESCRIPTION_FIELD]: {
    name: "Description",
    columnTypes: ["text", "long_text"],
    clearable: true,
    tooltipText:
      "You can either choose a 2-way synced column here or configure 1-way custom description below",
  },
  [LOCATION_FIELD]: {
    name: "Location",
    columnTypes: ["text"],
    clearable: true,
  },
  [INVITEES_FIELD]: {
    name: "Send event invite to",
    columnTypes: ["people", "email"],
    clearable: true,
    tooltipText:
      "You can choose multiple people or email types column here. Invites will be send to all the users part of those columns",
  },
  [EVENT_LINK_FIELD]: {
    name: "Add event link to column",
    columnTypes: ["link"],
    clearable: true,
  },
  [EVENT_STATUS_FIELD]: {
    name: "Add event status to column",
    columnTypes: ["status"],
    clearable: true,
    tooltipText: "Calendar event status ('confirmed' or 'cancelled')",
  },
};

export const settingInfos = {
  [SYNC_PERIOD]: {
    label: "Keep event-item sync until",
    values: [1, 3, 6, 12],
    timeUnit: "month",
    suffix: "after event end",
    tooltipText:
      "You can choose how long to keep event fields and item columns synced after event's end time has passed",
  },
};

const defaultMapping = {
  [TITLE_FIELD]: "",
  [START_TIME_FIELD]: "",
  [END_TIME_FIELD]: "",
  [TIMELINE_FIELD]: "",
  [DURATION_FIELD]: undefined,
  [DESCRIPTION_FIELD]: "",
  [LOCATION_FIELD]: "",
  [INVITEES_FIELD]: "",
  [EVENT_STATUS_FIELD]: "",
  [EVENT_LINK_FIELD]: "",
  [CUSTOM_DESCRIPTION_FIELD]: "",
};

const defaultIntegrationConfig = {
  1: {},
  2: {},
  3: {},
  4: {},
};

const ConnectionContext = createContext();
const monday = mondaySdk();

export const ConnectionProvider = ({ children }) => {
  const [appName, setAppName] = useState("");
  const [sessionToken, setSessionToken] = useState("");
  const [isOAuthComplete, setIsOAuthComplete] = useState(true);
  const [oauthFlowInitiated, setOAuthFlowInitiated] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [userId, setUserId] = useState("");
  const [boardId, setBoardId] = useState("");
  const [approvedScopes, setApprovedScopes] = useState([]);
  const [subscription, setSubscription] = useState(undefined);
  const [itemColumns, setItemColumns] = useState([]);
  const [subitemBoardId, setSubitemBoardId] = useState("");
  const [subitemColumns, setSubitemColumns] = useState([]);
  const [columnsInitialized, setColumnsInitialized] = useState(false);
  const [columns, setColumns] = useState([]);
  const [isConnectionLoading, setIsConnectionLoading] = useState(true);
  const [emailIdOptions, setEmailIdOptions] = useState([]);
  const [emailId, setEmailId] = useState("");
  const [calendarOptions, setCalendarOptions] = useState([]);
  const [calendarId, setCalendarId] = useState("");
  const [isSubitemMode, setIsSubitemMode] = useState(false);
  const [showModeSelector, setShowModeSelector] = useState(false);
  const [mapping, setMapping] = useState(defaultMapping);
  const [settings, setSettings] = useState({
    [SYNC_PERIOD]: settingInfos[SYNC_PERIOD].values[0],
  });
  const [timingMode, setTimingMode] = useState(1);
  const [timelineFieldEnabled, setTimelineFieldEnabled] = useState(false);
  const [durationFieldEnabled, setDurationFieldEnabled] = useState(false);
  const [customDescriptionBuilderEnabled, setCustomDescriptionBuilderEnabled] = useState(false);
  const [integrationConfig, setIntegrationConfig] = useState(defaultIntegrationConfig);
  const [localIntegrationConfig, setLocalIntegrationConfig] = useState(defaultIntegrationConfig);
  const [importPeriod, setImportPeriod] = useState(1);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoveProviderLoading, setIsRemoveProviderLoading] = useState(false);
  const [inEditMode, setInEditMode] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [message, setMessage] = useState("");
  const [isUserProviderAccountOwner, setIsUserProviderAccountOwner] = useState(false);
  const [isUserBoardOwner, setIsUserBoardOwner] = useState(false);
  const [isUserViewer, setIsUserViewer] = useState(false);
  const [areFieldsDisabled, setAreFieldsDisabled] = useState(false);
  const [showSubscribeNudge, setShowSubscribeNudge] = useState(false);

  useEffect(() => {
    monday.get("sessionToken").then((res) => setSessionToken(res.data));

    monday.get("context").then(async (res) => {
      if (!res.data) {
        return;
      }

      const {
        app: { id: appId },
        boardId,
        account: { id: accountId },
        user: { id: userId, isViewOnly },
        permissions: { approvedScopes },
      } = res.data;

      setAppName(appId === 10145063 ? GOOGLE_CALENDAR_SYNC : OUTLOOK_CALENDAR_SYNC);
      setAccountId(accountId);
      setUserId(userId);
      setBoardId(boardId);
      setApprovedScopes(approvedScopes);
      setIsUserViewer(isViewOnly);

      if (res.data.subscription) {
        setSubscription(res.data.subscription);
      }

      let query = `query ($boardIds: [ID!]) {
        boards (ids: $boardIds) {
          columns {
            id
            title
            type
            settings_str
          }
          owners {
            id
          }
        }
      }`;
      let variables = { boardIds: [boardId] };

      try {
        const response = await monday.api(query, { variables });
        const board = response.data.boards[0];

        setItemColumns(board.columns);
        setColumns(board.columns);
        if (board.owners.filter(({ id }) => id === userId).length) {
          setIsUserBoardOwner(true);
        }
      } catch (error) {
        console.error("Get columns error:", error.message);
      }

      query = `query ($boardId: ID!) {
        boards (ids: [$boardId]) {
          items_page (limit: 100) {
            items {
              subitems {
                board {
                  id
                  columns {
                    id
                    title
                    type
                    settings_str
                  }
                }
              }
            }
          }
        }
      }`;
      variables = { boardId };

      try {
        const response = await monday.api(query, { variables });
        const itemsPage = response.data.boards[0]?.items_page ?? [];

        for (let { subitems } of itemsPage.items) {
          if (subitems.length) {
            setSubitemBoardId(subitems[0].board.id);
            setSubitemColumns(subitems[0].board.columns);
            setShowModeSelector(true);
            break;
          }
        }
      } catch (error) {
        console.error("Get subitems' columns error:", error.message);
      }

      setColumnsInitialized(true);
    });
  }, []);

  const headers = { Authorization: sessionToken };

  useEffect(() => {
    const getExistingConnection = async () => {
      setIsConnectionLoading(true);

      let response;
      try {
        response = await axios.get(CONNECTION_API_URL + boardId, { headers });
        setIsConnectionLoading(false);
      } catch (error) {
        console.log(error);
        const data = error.response.data;

        if (data?.error?.includes("OAuth")) {
          setIsOAuthComplete(false);
          setIsConnectionLoading(false);
        } else {
          setErrorMessage("Something went wrong", error.response.status);
        }
        return;
      }

      const { oauthScopes, emailIds, calendars, connection } = response.data;

      if (!areArraysEqual(oauthScopes, approvedScopes)) {
        setIsOAuthComplete(false);
        return;
      }

      setEmailIdOptions([
        ...emailIds.map((emailId) => ({ value: emailId, label: emailId })),
        {
          value: "add_another_account",
          label: "+ Add another account",
        },
      ]);

      setCalendarOptions(
        calendars.map(({ id, title }) => ({
          value: id,
          label: truncateString(id === title ? `Primary - ${title}` : title, 28 /* maxLength */),
        }))
      );

      if (!connection) {
        // Case when no schedule is ever configured for this item.
        setEmailId(emailIds[0]);
        return;
      }

      const {
        emailId,
        calendarId,
        mapping,
        settings,
        integrationConfig,
        subitemBoardId,
        createdBy,
      } = connection;

      setIsSubitemMode(!!subitemBoardId);
      setSubitemBoardId(subitemBoardId);
      setColumns(subitemBoardId ? subitemColumns : itemColumns);

      setMapping({
        ...mapping,
        [DESCRIPTION_FIELD]: mapping[CUSTOM_DESCRIPTION_FIELD]
          ? CUSTOM_DESCRIPTION_SPECIAL_VALUE
          : mapping[DESCRIPTION_FIELD],
        [CUSTOM_DESCRIPTION_FIELD]: mapping[CUSTOM_DESCRIPTION_FIELD] || "",
      });

      setTimelineFieldEnabled(!!mapping[TIMELINE_FIELD]);
      setDurationFieldEnabled(!!mapping[DURATION_FIELD]);
      setCustomDescriptionBuilderEnabled(mapping[CUSTOM_DESCRIPTION_FIELD]);

      setEmailId(emailId);
      setCalendarId(calendarId);
      setSettings(settings);
      setIntegrationConfig(integrationConfig);
      setLocalIntegrationConfig(integrationConfig);
      setIsActive(true);
      setIsUserProviderAccountOwner(createdBy === userId.toString());
    };

    if (columnsInitialized) {
      getExistingConnection();
    }
    // eslint-disable-next-line
  }, [sessionToken, boardId, columnsInitialized]);

  useEffect(() => {
    if (!isActive) {
      setMapping(defaultMapping);
      setColumns(isSubitemMode ? subitemColumns : itemColumns);
      setIntegrationConfig(
        isSubitemMode ? { ...defaultIntegrationConfig, 3: undefined } : defaultIntegrationConfig
      );
    }
  }, [isSubitemMode]);

  useEffect(() => {
    setAreFieldsDisabled(
      isLoading ||
        isRemoveProviderLoading ||
        !isUserBoardOwner ||
        isUserViewer ||
        (isActive && !inEditMode)
    );
  }, [isLoading, isRemoveProviderLoading, isUserBoardOwner, isUserViewer, isActive, inEditMode]);

  const startOAuthFlow = async () => {
    const url = `${AUTH_API_URL}url?scope=${approvedScopes.join(",")}&isElectronApp=${window.navigator?.userAgent?.includes("Electron")}`;
    const response = await axios.get(url, { headers });

    let authUrl = response.data;
    if (authUrl.includes("auth.monday")) {
      const slug = decodeJwt(sessionToken).dat.slug;
      authUrl += `&subdomain=${slug}`;
    }

    setOAuthFlowInitiated(true);
    monday.execute("openLinkInTab", { url: authUrl });
  };

  const startProviderOAuthFlow = async () => {
    const response = await axios.get(AUTH_API_URL + "provider/url", { headers });
    setOAuthFlowInitiated(true);
    window.open(response.data);
  };

  const setErrorMessage = (prefixMessage, statusCode) => {
    if (!statusCode) {
      setMessage(prefixMessage);
    } else if (statusCode === 400) {
      setMessage(`${prefixMessage}! Invalid data provided`);
    } else if (statusCode === 401) {
      setMessage(`${prefixMessage}! Authorization error`);
    } else {
      setMessage(`${prefixMessage}! Please try again or contact app support`);
    }
  };

  const validateMapping = () => {
    const relevantFields = [
      [TITLE_FIELD, true],
      [START_TIME_FIELD, !timelineFieldEnabled],
      [END_TIME_FIELD, !timelineFieldEnabled && !durationFieldEnabled],
      [TIMELINE_FIELD, timelineFieldEnabled],
      [DURATION_FIELD, durationFieldEnabled],
    ];

    for (let [fieldId, isEnabled] of relevantFields) {
      if (isEnabled && !mapping[fieldId]) {
        setValidationError(`Please select column for ${fieldInfos[fieldId].name}`);
        return false;
      }
    }

    if (!timelineFieldEnabled && mapping[START_TIME_FIELD] === mapping[END_TIME_FIELD]) {
      setValidationError("Columns for Start Time and End Time cannot be same");
      return false;
    }
    return true;
  };

  const validateCreateRequest = () => {
    if (!calendarId) {
      setValidationError("Please select a calendar");
      return false;
    }

    if (!validateMapping()) {
      return false;
    }

    setValidationError("");
    return true;
  };

  const validateUpdateRequest = () => {
    if (!validateMapping()) {
      return false;
    }

    setValidationError("");
    return true;
  };

  const validateSubscription = () => {
    // Admin/Dev account ID.
    if (accountId === "21034321") {
      return true;
    }

    if (!subscription) {
      monday.execute("openPlanSelection", { isInPlanSelection: true });
      setShowSubscribeNudge(true);
      return false;
    }
    return true;
  };

  const handleError = (error, prefixMessage) => {
    console.log(error);
    const data = error.response.data;

    if (data?.error?.includes("plan")) {
      setShowSubscribeNudge(true);
    } else if (data?.error?.includes("Google Account is linked to some board")) {
      setErrorMessage(
        prefixMessage +
          "! Google Account is linked to some board, please remove that connection first."
      );
    } else if (data?.error?.includes("already connected")) {
      setErrorMessage(prefixMessage + "! Calendar already connected to another board");
    } else {
      setErrorMessage(prefixMessage, error.response.status);
    }
  };

  const fetchAndUpdateCalendars = async (emailId) => {
    setIsConnectionLoading(true);

    let response;
    try {
      // Making this a POST request instead of GET reqeuest intentionally so that email addresses
      // (PII) are not logged when logging endpoints.
      response = await axios.post(BASE_API_URL + "calendars", { emailId }, { headers });
      setIsConnectionLoading(false);
    } catch (error) {
      handleError(error, "Unable to fetch calendars for this account");
      return;
    }

    const { calendars } = response.data;

    setCalendarOptions(
      calendars.map(({ id, title }) => ({
        value: id,
        label: truncateString(id === title ? `Primary - ${title}` : title, 28 /* maxLength */),
      }))
    );
    setCalendarId("");
  };

  const createConnection = async () => {
    if (!validateCreateRequest() || !validateSubscription()) {
      return;
    }

    const finalMapping = { ...mapping };
    if (finalMapping[DESCRIPTION_FIELD] === CUSTOM_DESCRIPTION_SPECIAL_VALUE) {
      finalMapping[DESCRIPTION_FIELD] = "";
    } else {
      finalMapping[CUSTOM_DESCRIPTION_FIELD] = "";
    }

    const body = {
      boardId,
      emailId,
      calendarId,
      mapping: finalMapping,
      settings,
      integrationConfig,
      subitemBoardId: isSubitemMode ? subitemBoardId : undefined,
    };

    setIsLoading(true);
    try {
      await axios.post(CONNECTION_API_URL, body, { headers });
      setIsActive(true);
      setIsUserProviderAccountOwner(true);
      setMessage("Connection created successfully");
      monday.execute("valueCreatedForUser");
    } catch (error) {
      handleError(error, "Connection creation failed");
    } finally {
      setIsLoading(false);
    }
  };

  const updateConnection = async () => {
    if (!validateUpdateRequest() || !validateSubscription()) {
      return;
    }

    const finalMapping = { ...mapping };
    if (finalMapping[DESCRIPTION_FIELD] === CUSTOM_DESCRIPTION_SPECIAL_VALUE) {
      finalMapping[DESCRIPTION_FIELD] = "";
    } else {
      finalMapping[CUSTOM_DESCRIPTION_FIELD] = "";
    }

    const body = { boardId, mapping: finalMapping, settings };

    setIsLoading(true);
    try {
      await axios.put(CONNECTION_API_URL, body, { headers });
      setMessage("Connection updated successfully");
      setInEditMode(false);
    } catch (error) {
      handleError(error, "Connection updation failed");
    } finally {
      setIsLoading(false);
    }
  };

  const updateIntegration = async () => {
    if (!validateSubscription()) {
      return;
    }

    const body = { boardId, integrationConfig: localIntegrationConfig };

    setIsLoading(true);
    try {
      await axios.put(CONNECTION_API_URL, body, { headers });
      setMessage("Integration updated successfully");
      setIntegrationConfig(localIntegrationConfig);
    } catch (error) {
      handleError(error, "Integration updation failed");
    } finally {
      setIsLoading(false);
    }
  };

  const deleteConnection = async () => {
    setIsLoading(true);
    try {
      await axios.delete(CONNECTION_API_URL + boardId, { headers });
      setMessage("Connection deleted successfully");
      setIsActive(false);
      window.location.reload(false);
    } catch (error) {
      handleError(error, "Connection deletion failed");
    } finally {
      setIsLoading(false);
    }
  };

  const importExistingEvents = async () => {
    setIsLoading(true);
    try {
      await axios.post(CONNECTION_API_URL + boardId + "/import-events", {}, { headers });
      setMessage(
        "Importing of existing calendar events has started! You will get notified once it is finished."
      );
    } catch (error) {
      handleError(error, "Event importing failed");
    } finally {
      setIsLoading(false);
    }
  };

  const removeProviderAccount = async () => {
    setIsRemoveProviderLoading(true);
    try {
      await axios.post(AUTH_API_URL + "provider/delete", { emailId }, { headers });
      setMessage("Account removed successfully");
      window.location.reload(false);
    } catch (error) {
      handleError(error, "Account removal failed");
    } finally {
      setIsRemoveProviderLoading(false);
    }
  };

  const addNewColumn = async (type, name) => {
    const query = `mutation ($boardId: ID!, $title: String!, $columnType: ColumnType!) {
      create_column(board_id: $boardId, title: $title, column_type: $columnType) {
        id
        title
        type
        settings_str
      }
    }`;
    const variables = {
      boardId: isSubitemMode ? subitemBoardId : boardId,
      title: name,
      columnType: type,
    };

    try {
      const response = await monday.api(query, { variables });
      const column = response.data.create_column;
      if (isSubitemMode) {
        setSubitemColumns([...subitemColumns, column]);
        setColumns([...columns, column]);
      } else {
        setItemColumns([...itemColumns, column]);
        setColumns([...columns, column]);
      }
      return column;
    } catch (error) {
      console.error("Create Column error:", error.message);
    }
  };

  return (
    <ConnectionContext.Provider
      value={{
        appName,
        isOAuthComplete,
        oauthFlowInitiated,
        setOAuthFlowInitiated,
        accountId,
        approvedScopes,
        columns,
        isConnectionLoading,
        emailIdOptions,
        emailId,
        setEmailId,
        calendarOptions,
        calendarId,
        setCalendarId,
        isSubitemMode,
        setIsSubitemMode,
        showModeSelector,
        mapping,
        setMapping,
        settings,
        setSettings,
        timingMode,
        setTimingMode,
        timelineFieldEnabled,
        setTimelineFieldEnabled,
        durationFieldEnabled,
        setDurationFieldEnabled,
        customDescriptionBuilderEnabled,
        setCustomDescriptionBuilderEnabled,
        integrationConfig,
        localIntegrationConfig,
        setLocalIntegrationConfig,
        importPeriod,
        setImportPeriod,
        isActive,
        isLoading,
        isRemoveProviderLoading,
        inEditMode,
        setInEditMode,
        validationError,
        setValidationError,
        message,
        setMessage,
        isUserProviderAccountOwner,
        isUserBoardOwner,
        isUserViewer,
        areFieldsDisabled,
        showSubscribeNudge,
        startOAuthFlow,
        startProviderOAuthFlow,
        fetchAndUpdateCalendars,
        createConnection,
        updateConnection,
        deleteConnection,
        updateIntegration,
        importExistingEvents,
        removeProviderAccount,
        addNewColumn,
      }}
    >
      {children}
    </ConnectionContext.Provider>
  );
};

export default ConnectionContext;
