import { useContext } from "react";
import { Flex, Text, Dropdown, Tooltip } from "monday-ui-react-core";
import InfoIcon from "monday-ui-react-core/dist/icons/Info";
import ConnectionContext, { settingInfos } from "../../context/ConnectionContext";

const SettingValueSelector = ({ type }) => {
  const settingInfo = settingInfos[type];
  const { settings, setSettings, areFieldsDisabled } = useContext(ConnectionContext);

  const columnOptions = settingInfo.values.map((value) => ({
    value,
    label: `${value} ${settingInfo.timeUnit}${value === 1 ? "" : "s"} ${settingInfo.suffix}`,
  }));

  const selectedOption = settingInfos[type]
    ? columnOptions.filter((option) => option.value === settings[type])[0]
    : columnOptions[0];

  const onChange = (option) => {
    setSettings({ ...settings, [type]: option ? option.value : columnOptions[0] });
  };

  return (
    <Flex gap={10}>
      <Text type="text1" className="label">
        {settingInfo.label}
        <Tooltip content={settingInfo.tooltipText} withMaxWidth position="left">
          <InfoIcon size={15} />
        </Tooltip>
      </Text>

      <div className="selector">
        <Dropdown
          size="small"
          clearable={false}
          options={columnOptions}
          value={selectedOption}
          disabled={areFieldsDisabled}
          onChange={onChange}
        />
      </div>
    </Flex>
  );
};

export default SettingValueSelector;
