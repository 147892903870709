import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Button, Loader } from "monday-ui-react-core";
import ConnectionContext from "../../context/ConnectionContext";

const areObjectsSame = (object1, object2) => {
  const keys1 = Object.keys(object1).sort();
  const keys2 = Object.keys(object2).sort();

  if (JSON.stringify(keys1) !== JSON.stringify(keys2)) {
    return false;
  }

  for (let key in object1) {
    const value1 = object1[key];
    const value2 = object2[key];

    if (typeof value1 === "object" && typeof value2 === "object") {
      if (!areObjectsSame(value1, value2)) {
        return false;
      }
    } else if (value1 !== value2) {
      return false;
    }
  }

  return true;
};

const ButtonGroup = () => {
  const {
    integrationConfig,
    localIntegrationConfig,
    setLocalIntegrationConfig,
    isLoading,
    isUserProviderAccountOwner,
    isUserBoardOwner,
    isUserViewer,
    updateIntegration,
  } = useContext(ConnectionContext);

  const navigate = useNavigate();
  const isViewerOrNotBoardOwner = !isUserBoardOwner || isUserViewer;

  const onBack = () => {
    setLocalIntegrationConfig(integrationConfig);
    navigate("/");
  };

  return (
    <Flex className="button-group" justify="Center">
      {isLoading ? (
        <Loader size={40} />
      ) : (
        <Flex direction="Column" gap={10}>
          {!areObjectsSame(integrationConfig, localIntegrationConfig) && (
            <Button
              color="positive"
              className="button"
              onClick={updateIntegration}
              disabled={!isUserProviderAccountOwner || isViewerOrNotBoardOwner}
            >
              Save Changes
            </Button>
          )}

          <Button
            color="primary"
            className="button"
            onClick={onBack}
            disabled={isViewerOrNotBoardOwner}
          >
            Go Back
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default ButtonGroup;
