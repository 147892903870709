import { useContext, useState } from "react";
import { Flex, Text, Dropdown, Tooltip } from "monday-ui-react-core";
import InfoIcon from "monday-ui-react-core/dist/icons/Info";
import ConnectionContext, { fieldInfos } from "../../context/ConnectionContext";
import AddColumnModal from "./AddColumnModal";

const InviteesColumnsSelector = ({ fieldId }) => {
  const fieldInfo = fieldInfos[fieldId];
  const { mapping, setMapping, columns, areFieldsDisabled } = useContext(ConnectionContext);

  const [showAddColumnModal, setShowAddColumnModal] = useState(false);

  const columnOptions = [
    ...columns
      .filter((column) => fieldInfo.columnTypes.includes(column.type))
      .map(({ id, title }) => ({ value: id, label: title })),
    { value: "add_new_column", label: "+ Add new column" },
  ];

  const selectedOptions = mapping[fieldId]
    ? columnOptions.filter((option) => mapping[fieldId].includes(option.value))
    : [];

  const onChange = (options) => {
    if (!options) {
      setMapping({ ...mapping, [fieldId]: [] });
      return;
    }

    if (options.some(({ value }) => value === "add_new_column")) {
      setShowAddColumnModal(true);
    } else {
      setMapping({ ...mapping, [fieldId]: options.map(({ value }) => value) });
    }
  };

  const setNewColumnOption = (option) => {
    const currentValues = mapping[fieldId];
    setMapping({ ...mapping, [fieldId]: [...currentValues, option.value] });
  };

  return (
    <>
      <Flex gap={10}>
        <Text type="text1" className="label">
          {fieldInfo.name}
          {fieldInfo.tooltipText && (
            <Tooltip content={fieldInfo.tooltipText} withMaxWidth position="left">
              <InfoIcon size={15} />
            </Tooltip>
          )}
        </Text>

        <div className="selector">
          <Dropdown
            size="small"
            multi
            multiline
            clearable={fieldInfo.clearable}
            options={columnOptions}
            value={selectedOptions}
            disabled={areFieldsDisabled}
            onChange={onChange}
          />
        </div>
      </Flex>

      <AddColumnModal
        showAddColumnModal={showAddColumnModal}
        setShowAddColumnModal={setShowAddColumnModal}
        columnTypes={fieldInfo.columnTypes}
        setNewColumnOption={setNewColumnOption}
      />
    </>
  );
};

export default InviteesColumnsSelector;
