import { useContext } from "react";
import { Flex, Text, Dropdown } from "monday-ui-react-core";
import ConnectionContext from "../../context/ConnectionContext";

const CalendarSelector = () => {
  const { calendarOptions, calendarId, setCalendarId, isActive, areFieldsDisabled } =
    useContext(ConnectionContext);

  const selectedOption = calendarId
    ? calendarOptions.filter((option) => option.value === calendarId)[0]
    : null;

  const onChange = (option) => {
    setCalendarId(option ? option.value : "");
  };

  return (
    <Flex gap={10}>
      <Text type="text1" className="label">
        Choose Calendar
      </Text>

      <div className="selector">
        <Dropdown
          size="small"
          clearable={false}
          options={calendarOptions}
          value={selectedOption}
          disabled={isActive || areFieldsDisabled}
          onChange={onChange}
        />
      </div>
    </Flex>
  );
};

export default CalendarSelector;
