import { useContext, useState } from "react";
import {
  Flex,
  Text,
  Dropdown,
  AttentionBox,
  IconButton,
  ModalContent,
  Modal,
  ModalHeader,
  ModalFooterButtons,
  Loader,
} from "monday-ui-react-core";
import DeleteIcon from "monday-ui-react-core/dist/icons/Delete";
import { Heading } from "monday-ui-react-core/next";
import ConnectionContext from "../../context/ConnectionContext";
import { GOOGLE_CALENDAR_SYNC } from "../../utils/constants";

const EmailIdSelector = () => {
  const {
    appName,
    emailIdOptions,
    emailId,
    setEmailId,
    isActive,
    isRemoveProviderLoading,
    areFieldsDisabled,
    oauthFlowInitiated,
    setOAuthFlowInitiated,
    startProviderOAuthFlow,
    fetchAndUpdateCalendars,
    removeProviderAccount,
  } = useContext(ConnectionContext);

  const [showModal, setShowModal] = useState(false);

  const accountType = appName === GOOGLE_CALENDAR_SYNC ? "Google" : "Microsoft";

  const selectedOption = emailId
    ? emailIdOptions.filter((option) => option.value === emailId)[0]
    : null;

  const onChange = (option) => {
    if (option && option.value === emailId) {
      return;
    }

    setEmailId(option ? option.value : "");
    if (option.value === "add_another_account") {
      startProviderOAuthFlow();
    } else {
      fetchAndUpdateCalendars(option.value);
      setOAuthFlowInitiated(false);
    }
  };

  const onConfirm = () => {
    removeProviderAccount();
    setShowModal(false);
  };

  const onCancel = () => {
    setShowModal(false);
  };

  return (
    <>
      {oauthFlowInitiated && (
        <AttentionBox
          className="monday-storybook-attention-box_box"
          onClose={() => setOAuthFlowInitiated(false)}
          text="Please refresh the browser after the new account has been successfully connected"
          title="Important"
          type="warning"
        />
      )}

      <Flex gap={10} style={{ position: "relative" }}>
        <Text type="text1" className="label">
          {accountType} Account
        </Text>

        <div className="selector">
          <Dropdown
            size="small"
            clearable={false}
            options={emailIdOptions}
            value={selectedOption}
            disabled={isActive || areFieldsDisabled}
            onChange={onChange}
          />
        </div>

        {emailId && !isActive && (
          <div style={{ position: "absolute", right: "-50px" }}>
            {isRemoveProviderLoading ? (
              <Loader size={20} />
            ) : (
              <IconButton
                disabled={areFieldsDisabled}
                ariaLabel="Remove Google Account"
                icon={DeleteIcon}
                onClick={() => setShowModal(true)}
              />
            )}
          </div>
        )}
      </Flex>

      <Modal contentSpacing id="story-book-modal" onClose={onCancel} show={showModal}>
        <ModalHeader>
          <Heading type="h3">Remove Google Account</Heading>
        </ModalHeader>

        <ModalContent>
          <Flex direction="Column" gap={20}>
            <Text type="text1" ellipsis={false}>
              Are you sure you want to remove this Google Account{" "}
              <em>
                <strong>{emailId}</strong>
              </em>
            </Text>
          </Flex>
        </ModalContent>

        <ModalFooterButtons
          onPrimaryButtonClick={onConfirm}
          onSecondaryButtonClick={onCancel}
          primaryButtonText="Confirm"
          secondaryButtonText="Cancel"
        />
      </Modal>
    </>
  );
};

export default EmailIdSelector;
