import { useContext } from "react";
import { Flex, Text, Dropdown } from "monday-ui-react-core";
import ConnectionContext from "../../context/ConnectionContext";

const ModeSelector = () => {
  const { isSubitemMode, setIsSubitemMode, isActive, areFieldsDisabled } =
    useContext(ConnectionContext);

  const options = [
    { value: false, label: "Items" },
    { value: true, label: "Subitems" },
  ];
  const selectedOption = options[isSubitemMode ? 1 : 0];

  const onChange = (option) => {
    setIsSubitemMode(option.value);
  };

  return (
    <Flex gap={10}>
      <Text type="text1" className="label">
        Choose Mode
      </Text>

      <div className="selector">
        <Dropdown
          size="small"
          clearable={false}
          options={options}
          value={selectedOption}
          disabled={isActive || areFieldsDisabled}
          onChange={onChange}
        />
      </div>
    </Flex>
  );
};

export default ModeSelector;
