import { useContext, useEffect, useState } from "react";
import { Checkbox, Flex, Text } from "monday-ui-react-core";
import ConnectionContext from "../../context/ConnectionContext";

const Recipe = ({ type, recipeText }) => {
  const { localIntegrationConfig, setLocalIntegrationConfig, isUserProviderAccountOwner } =
    useContext(ConnectionContext);

  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (type in localIntegrationConfig) {
      setEnabled(true);
    }
  }, [type, localIntegrationConfig]);

  const onClick = (e) => {
    e.preventDefault();
    if (!isUserProviderAccountOwner) {
      return;
    }

    if (!enabled) {
      setEnabled(true);
      setLocalIntegrationConfig({ ...localIntegrationConfig, [type]: {} });
    } else {
      setEnabled(false);
      const config = { ...localIntegrationConfig };
      delete config[type];
      setLocalIntegrationConfig(config);
    }
  };

  return (
    <div className={`recipe ${enabled ? "recipe-enabled" : "recipe-disabled"}`} onClick={onClick}>
      <Flex direction="Column" gap={10}>
        <Flex gap={10}>
          <Checkbox checked={enabled} disabled={!isUserProviderAccountOwner} />

          <Text type="text1" ellipsis={false} className="recipe-text">
            {recipeText}
          </Text>
        </Flex>
      </Flex>
    </div>
  );
};

export default Recipe;
