import { useContext, useState } from "react";
import {
  AttentionBox,
  Dropdown,
  Flex,
  Modal,
  ModalContent,
  ModalFooterButtons,
  ModalHeader,
  Text,
  TextField,
} from "monday-ui-react-core";
import { Heading } from "monday-ui-react-core/next";
import ConnectionContext from "../../context/ConnectionContext";
import { capitalize } from "../../utils/utils";

const AddColumnModal = ({
  showAddColumnModal,
  setShowAddColumnModal,
  columnTypes,
  setNewColumnOption,
}) => {
  const { addNewColumn } = useContext(ConnectionContext);

  const [columnTypeOption, setColumnTypeOption] = useState();
  const [columName, setColumName] = useState();
  const [validationError, setValidationError] = useState("");

  const options = [
    ...columnTypes
      .filter((type) => type !== "name")
      .map((type) => ({
        value: type,
        label: capitalize(type.replaceAll("_", " ")),
      })),
  ];

  const onSelectionChange = (option) => {
    if (option) {
      setColumnTypeOption(option);
    }
  };

  const onInputChange = (value) => {
    setColumName(value);
  };

  const onAdd = async () => {
    if (!columnTypeOption) {
      setValidationError("Please select a column type");
      return;
    }
    if (!columName) {
      setValidationError("Please fill in a column name");
      return;
    }
    setValidationError("");

    const response = await addNewColumn(columnTypeOption.value, columName);
    setNewColumnOption({ value: response.id, label: response.title });
    onCancel();
  };

  const onCancel = () => {
    setColumName("");
    setColumnTypeOption(null);
    setShowAddColumnModal(false);
  };

  return (
    <Modal
      contentSpacing
      id="story-book-modal"
      onClose={onCancel}
      show={showAddColumnModal}
      width={350}
    >
      <ModalHeader>
        <Heading type="h2">Add new column</Heading>
      </ModalHeader>

      <ModalContent>
        <Flex direction="Column" gap={20} className="modal modal-flex">
          <Flex justify="SpaceBetween" className="modal-flex">
            <Text type="text1" ellipsis={false}>
              Column Type
            </Text>
            <div className="column-type-selector">
              <Dropdown
                size="small"
                clearable={false}
                options={options}
                value={columnTypeOption}
                onChange={onSelectionChange}
              />
            </div>
          </Flex>

          <Flex justify="SpaceBetween" className="modal-flex">
            <Text type="text1" ellipsis={false}>
              Column Name
            </Text>
            <TextField
              placeholder="Enter column name"
              wrapperClassName="column-name-field"
              value={columName}
              onChange={onInputChange}
            />
          </Flex>

          {validationError && <AttentionBox text={validationError} type="danger" />}
        </Flex>
      </ModalContent>

      <ModalFooterButtons
        onPrimaryButtonClick={onAdd}
        onSecondaryButtonClick={onCancel}
        primaryButtonText="Add"
        secondaryButtonText="Cancel"
      />
    </Modal>
  );
};

export default AddColumnModal;
