import { useContext, useRef } from "react";
import { Flex, Text } from "monday-ui-react-core";
import WandIcon from "monday-ui-react-core/dist/icons/Wand";
import ConnectionContext from "../../context/ConnectionContext";
import { CUSTOM_DESCRIPTION_FIELD } from "../../utils/constants";

const fieldId = CUSTOM_DESCRIPTION_FIELD;
const allowedColumnTypes = [
  "text",
  "long_text",
  "numbers",
  "link",
  "email",
  "people",
  "dropdown",
  "board_relation",
];

const CustomDescriptionBuilder = () => {
  const {
    isSubitemMode,
    columns,
    mapping,
    setMapping,
    customDescriptionBuilderEnabled,
    areFieldsDisabled,
  } = useContext(ConnectionContext);

  const textareaRef = useRef(null);

  const customDescription = mapping[fieldId];
  const variables = [
    { id: "board.id", title: "Board ID" },
    { id: "board.name", title: "Board Name" },
    { id: "pulse.id", title: "Item ID" },
    { id: "item.name", title: "Item Name" },
    ...(isSubitemMode
      ? [
          { id: "subitem.id", title: "Subitem ID" },
          { id: "subitem.name", title: "Subitem Name" },
        ]
      : []),
    ...columns
      .filter(({ type }) => allowedColumnTypes.includes(type))
      .map(({ id, title }) => ({ id: `item.${id}`, title })),
  ];

  const selectedVariables =
    customDescription?.match(/\{([^{}]+)\}/g)?.map((match) => match.slice(1, -1)) || [];

  const addTextAtCursor = (textToAdd) => {
    if (areFieldsDisabled) {
      return;
    }

    const textarea = textareaRef.current;
    const startPosition = textarea.selectionStart;
    const endPosition = textarea.selectionEnd;

    const finalText =
      customDescription.substring(0, startPosition) +
      textToAdd +
      customDescription.substring(endPosition);
    setMapping({ ...mapping, [fieldId]: finalText });

    setTimeout(() => {
      textarea.focus();
      textarea.selectionStart = startPosition + textToAdd.length;
      textarea.selectionEnd = startPosition + textToAdd.length;
    }, 0);
  };

  const onChange = (e) => {
    setMapping({ ...mapping, [fieldId]: e.target.value });
  };

  return (
    <Flex direction="Column" style={{ width: "100%", marginTop: ".5rem" }} gap={20} align="start">
      <Flex gap={5}>
        <Text type="text1">Custom Description Builder</Text>
        <WandIcon size={20} />
      </Flex>

      <textarea
        className="description-box"
        ref={textareaRef}
        value={
          customDescriptionBuilderEnabled
            ? customDescription
            : 'To enable custom event description, select < 1-way custom description > option in "Description" dropdown above'
        }
        onChange={onChange}
        placeholder="Add text here..."
        disabled={!customDescriptionBuilderEnabled || areFieldsDisabled}
      />

      <Flex wrap gap={10} className="variable-container">
        {variables.map(({ id, title }) => (
          <span
            key={id}
            className={`variable ${selectedVariables.includes(id) && customDescriptionBuilderEnabled && "variable-selected"} ${areFieldsDisabled && "variable-disabled"}`}
            onClick={() => addTextAtCursor(`{${id}}`)}
          >
            {title}
          </span>
        ))}
      </Flex>
    </Flex>
  );
};

export default CustomDescriptionBuilder;
