import { useContext } from "react";
import { Flex } from "monday-ui-react-core";
import { Heading } from "monday-ui-react-core/next";
import ConnectionContext from "../../context/ConnectionContext";
import image from "../../images/app-icon.png";

const AppHeading = () => {
  const { appName } = useContext(ConnectionContext);

  return (
    <Flex gap={10} justify="Center" className="main-heading">
      <Heading weight="light" type="h2">
        {appName}
      </Heading>
      <img src={image} alt="App Logo" style={{ width: "50px", borderRadius: "50%" }}></img>
    </Flex>
  );
};

export default AppHeading;
