import { useContext } from "react";
import { AttentionBox, Button, Flex, Text } from "monday-ui-react-core";
import MyWeekIcon from "monday-ui-react-core/dist/icons/MyWeek";
import { Heading } from "monday-ui-react-core/next";
import ConnectionContext from "../../context/ConnectionContext";

const OAuthScreen = () => {
  const { appName, oauthFlowInitiated, setOAuthFlowInitiated, startOAuthFlow, isUserViewer } =
    useContext(ConnectionContext);

  const calendarType = appName.split(" ")[0];

  return (
    <>
      {oauthFlowInitiated && (
        <AttentionBox
          className="monday-storybook-attention-box_box"
          onClose={() => setOAuthFlowInitiated(false)}
          text="Please refresh the browser after the account has been successfully connected"
          title="Important"
          type="warning"
        />
      )}

      <Flex direction="Column" gap={40} className="permission-box">
        <Flex gap={10}>
          <Heading weight="light" type="h2">
            {appName}
          </Heading>
          <MyWeekIcon size={30} />
        </Flex>

        <Text element="p" type="text1">
          This app needs to connect with your {calendarType} Calendar to sync your monday board
          items with calendar events
        </Text>

        <Button color="primary" onClick={() => startOAuthFlow(true)} disabled={isUserViewer}>
          Connect With {calendarType} Calendar
        </Button>
      </Flex>
    </>
  );
};

export default OAuthScreen;
